import React from "react"

import { useTranslation } from "react-i18next"

import { BILLING_URL } from "../../api"
import { FEATURE_FLAGS, PERMISSIONS } from "../../constants"
import { useCheckForFeatureFlag } from "../../hooks/useCheckForFeatureFlag"
import { useCheckForPermission } from "../../hooks/useCheckForPermission"
import { BILLING_PATHS, getPlanGroupPath } from "./Billing/constants"
import { BUILDINGS_PATHS } from "./Buildings/constants"
import { DEPARTMENT_PATHS } from "./Departments/constants"
import { DESKS_PATHS } from "./Desks/constants"
import { INTEGRATIONS_PATHS } from "./Integrations/constants"
import { ORGANIZATION_PATHS } from "./Organization/constants"
import { OVERVIEW_PATH } from "./Overview/constants"
import { VISITORS_PATHS } from "./Visitors/constants"

import { LIST_PLAN_GROUPS } from "../../redux/api/billing/constants"
import { useFetchSubscriptionsQuery } from "../../redux/api/billing/subscriptions"
import { groupToTranslationKey } from "../../redux/api/billing/utils"
import { getAppParams } from "../../redux/appParams/selectors"
import { useAppSelector } from "../../redux/reducers"
import { selectUser } from "../../redux/user/selectors"
import {
  isNormalUser,
  isOfficeManager,
  isPortalAdmin,
} from "../../redux/user/utils"

import AuxNav, { AuxButton, AuxSeparator } from "../../components/AuxNav"
import { NavMenu, NavSubItem } from "../../components/NavMenu"

import AccountSVG from "../../assets/images/icons/Account.svg"
import AssetSVG from "../../assets/images/icons/Asset.svg"
import BadgeSVG from "../../assets/images/icons/BadgeOutline.svg"
import BuildingSVG from "../../assets/images/icons/Building.svg"
import ChairSVG from "../../assets/images/icons/Chair.svg"
import DepartmentsSVG from "../../assets/images/icons/Departments.svg"
import HealthSVG from "../../assets/images/icons/Health.svg"
import IntegrationsSVG from "../../assets/images/icons/Integrations.svg"
import LabsSVG from "../../assets/images/icons/Labs.svg"
import FloorPlanSVG from "../../assets/images/icons/Maps.svg"
import OrganizationSVG from "../../assets/images/icons/Organization.svg"
import PaymentSVG from "../../assets/images/icons/Payment.svg"
import PeopleSVG from "../../assets/images/icons/People.svg"
import RoomSVG from "../../assets/images/icons/Room.svg"
import WorldSVG from "../../assets/images/icons/World.svg"

export const SettingsNav = () => {
  const { entry: currentUser } = useAppSelector(selectUser)
  const isUser = isNormalUser(currentUser)
  const isAdmin = isPortalAdmin(currentUser)
  const isManager = isOfficeManager(currentUser)

  const hasOfficeManagerRoomFlag = useCheckForFeatureFlag(
    FEATURE_FLAGS.OFFICE_MANAGER_ROOM_VISIBILITY,
  )

  const isLabsEligible = isAdmin

  const { data: { subsByPlanGroup } = {} } = useFetchSubscriptionsQuery()

  const { t } = useTranslation()

  const canEditCompanySettings = useCheckForPermission(
    PERMISSIONS.desks.canChangeDeskCompanySettings,
  )
  const canEditTimeSlots = useCheckForPermission(
    PERMISSIONS.desks.canChangeDeskTimeslot,
  )
  const canEditBuilding = useCheckForPermission(
    PERMISSIONS.buildings.canChangeBuilding,
  )
  const canEditFloor = useCheckForPermission(
    PERMISSIONS.floorPlans.canChangeFloor,
  )
  const canEditDepartments = useCheckForPermission(
    PERMISSIONS.departments.canChangeDepartment,
  )
  const canEditDesks = useCheckForPermission(PERMISSIONS.desks.canChangeDesk)
  const canEditRooms = useCheckForPermission(PERMISSIONS.desks.canChangeDesk)
  const canEditAmenities = useCheckForPermission(
    PERMISSIONS.amenities.canChangeAmenity,
  )

  const isBillingDisabled = useCheckForFeatureFlag("subscriptions.disabled")

  const canEditPeople = useCheckForPermission(PERMISSIONS.users.canChangeUser)
  const canEditHealth = useCheckForPermission(
    PERMISSIONS.health.canChangeHealth,
  )
  const canEditAssetTypes = useCheckForPermission(
    PERMISSIONS.assets.canChangeAssetType,
  )

  const canEditVisitorDocuments = useCheckForPermission(
    PERMISSIONS.documents.canChangeVisitorDocument,
  )
  const canEditVisitorTabletSettings = useCheckForPermission(
    PERMISSIONS.documents.canChangeVisitorTabletSettings,
  )
  const canEditVisitorPrinterSettings = useCheckForPermission(
    PERMISSIONS.documents.canChangeVisitorPrinterSettings,
  )

  const canEditCompany = useCheckForPermission(
    PERMISSIONS.companies.canChangeCompany,
  )

  const isPortalMergerEnabled = useCheckForFeatureFlag(
    FEATURE_FLAGS.PORTAL_MERGER,
  )

  const { isAppWhitelabel } = useAppSelector(getAppParams)

  return (
    <AuxNav>
      {/*
				Overview
			*/}
      {isPortalMergerEnabled && isAdmin && (
        <>
          <AuxButton to={OVERVIEW_PATH}>
            <WorldSVG />
            <span>{t("desktop.settings.navigation.overview")}</span>
          </AuxButton>
          <AuxSeparator />
        </>
      )}

      {/*
				Buildings
			*/}
      {canEditBuilding && (
        <AuxButton to={BUILDINGS_PATHS.root} isDisabled={!canEditBuilding}>
          <BuildingSVG />
          <span>{t("desktop.settings.buildings.title")}</span>
        </AuxButton>
      )}

      {/*
				Floor plans
			*/}
      {canEditFloor && (
        <AuxButton to="/settings/floor-plans" isDisabled={!canEditFloor}>
          <FloorPlanSVG />
          <span>{t("desktop.settings.floor_plans.title")}</span>
        </AuxButton>
      )}

      {/*
				Departments
			*/}
      {canEditDepartments && (
        <AuxButton to={DEPARTMENT_PATHS.root} isDisabled={!canEditDepartments}>
          <DepartmentsSVG />
          <span>{t("desktop.settings.departments.title")}</span>
        </AuxButton>
      )}

      {(canEditBuilding || canEditFloor || canEditDepartments) && (
        <AuxSeparator />
      )}

      {/*
				Desks
			*/}
      {canEditDesks && (
        <NavMenu
          to={isAdmin ? DESKS_PATHS.overview : DESKS_PATHS.general}
          text={t("desktop.settings.desks.title")}
          isDisabled={!canEditDesks}
          activePath="/settings/desks"
          icon={<ChairSVG />}
        >
          <>
            {isAdmin && (
              <NavSubItem to={DESKS_PATHS.overview}>
                {t("desktop.settings.billing.overview.title")}
              </NavSubItem>
            )}
            {canEditCompanySettings && (
              <NavSubItem to={DESKS_PATHS.general}>
                {t("desktop.settings.desks.general.title")}
              </NavSubItem>
            )}
            {canEditDesks && (
              <NavSubItem to={DESKS_PATHS.layout}>
                {t("desktop.settings.layout.title")}
              </NavSubItem>
            )}
            {canEditTimeSlots && (
              <NavSubItem to={DESKS_PATHS.timeSlots.default}>
                <span>{t("desktop.settings.timeslots.title")}</span>
              </NavSubItem>
            )}
            {canEditAmenities && (
              <NavSubItem to={DESKS_PATHS.amenities.default}>
                <span>{t("general.amenities")}</span>
              </NavSubItem>
            )}
          </>
        </NavMenu>
      )}

      {/*
				Rooms
			*/}
      {
        canEditRooms &&
        isPortalMergerEnabled &&
        (isAdmin || (isManager && hasOfficeManagerRoomFlag)) ? (
          <NavMenu
            to="/settings/rooms"
            text={t("desktop.settings.rooms.title")}
            isDisabled={!canEditRooms}
            activePath="/settings/rooms"
            icon={<RoomSVG />}
          >
            <NavSubItem isHidden={!isAdmin} to="/settings/rooms/overview">
              {t("desktop.settings.billing.overview.title")}
            </NavSubItem>
            <NavSubItem to="/settings/rooms/room-list">
              {t("desktop.settings.rooms.title")}
            </NavSubItem>
            <NavSubItem to="/settings/rooms/devices">
              {t("desktop.settings.rooms.devices.title")}
            </NavSubItem>
            <NavSubItem to="/settings/rooms/device-settings">
              {t("desktop.settings.rooms.device_settings.title")}
            </NavSubItem>
            <NavSubItem to="/settings/rooms/custom-content">
              {t("desktop.settings.rooms.custom_content.title")}
            </NavSubItem>
          </NavMenu>
        ) : null
        /* This may return in the future
          <AuxButton to="/settings/rooms" isDisabled={!canEditRooms}>
          <RoomSVG />
          <span>Rooms</span>
        </AuxButton>
        */
      }

      {/*
				Parking and assets
			*/}
      {canEditAssetTypes && (
        <AuxButton to="/settings/assets" isDisabled={!canEditAssetTypes}>
          <AssetSVG />
          <span>{t("desktop.settings.assets.title")}</span>
        </AuxButton>
      )}

      {/*
				Health
			*/}
      {canEditHealth && (
        <AuxButton to="/settings/health" isDisabled={!canEditHealth}>
          <HealthSVG />
          <span>{t("general.health")}</span>
        </AuxButton>
      )}

      {/*
				Visitor management
			*/}
      {canEditPeople && !isAppWhitelabel && (
        <NavMenu
          to={isAdmin ? VISITORS_PATHS.overview : VISITORS_PATHS.general}
          text={t("desktop.settings.visitors.title")}
          isDisabled={!canEditPeople}
          activePath={VISITORS_PATHS.root}
          icon={<BadgeSVG />}
        >
          <>
            {isAdmin && (
              <NavSubItem to={VISITORS_PATHS.overview}>
                {t(
                  "desktop.settings.navigation.plans_billing.sub_items.overview",
                )}
              </NavSubItem>
            )}
            <NavSubItem to={VISITORS_PATHS.general}>
              {t("desktop.settings.desks.general.title")}
            </NavSubItem>
            <NavSubItem to={VISITORS_PATHS.devices}>
              {t("desktop.settings.rooms.devices.title")}
            </NavSubItem>
            {(canEditVisitorTabletSettings ||
              canEditVisitorPrinterSettings) && (
              <NavSubItem
                to={VISITORS_PATHS.deviceSettingsTablet}
                activePath={VISITORS_PATHS.deviceSettingsLink}
              >
                {t("desktop.settings.rooms.device_settings.title")}
              </NavSubItem>
            )}
            {canEditVisitorDocuments && (
              <NavSubItem to={VISITORS_PATHS.documents}>
                {t("desktop.settings.visitors.documents.title")}
              </NavSubItem>
            )}
          </>
        </NavMenu>
      )}

      {/*
				Visitors
			*/}
      {isUser && !isAppWhitelabel && (
        <>
          <AuxButton
            to={VISITORS_PATHS.general}
            activePath={VISITORS_PATHS.root}
            hasSubitems
          >
            <BadgeSVG />
            <span>{t("desktop.settings.visitors.title")}</span>
          </AuxButton>
          <NavSubItem to={VISITORS_PATHS.general}>
            {t("desktop.settings.desks.general.title")}
          </NavSubItem>
        </>
      )}

      {(canEditDesks || canEditRooms || canEditHealth || canEditPeople) && (
        <AuxSeparator />
      )}

      {/*
				Account
			*/}
      <AuxButton to="/settings/profile">
        <AccountSVG />
        <span>{t("general.account.title")}</span>
      </AuxButton>

      {/*
				User directory
			*/}
      {canEditPeople && (
        <AuxButton to="/settings/people" isDisabled={!canEditPeople}>
          <PeopleSVG />
          <span>{t("desktop.settings.people.title")}</span>
        </AuxButton>
      )}

      {/*
				Integrations
			*/}
      {isAdmin && isPortalMergerEnabled && (
        <>
          <NavMenu
            icon={<IntegrationsSVG />}
            text={t("desktop.settings.integrations.title")}
            to={INTEGRATIONS_PATHS.root}
            activePath={INTEGRATIONS_PATHS.root}
            isDisabled={!canEditPeople}
          >
            <NavSubItem isHidden={!isAdmin} to={INTEGRATIONS_PATHS.calendars}>
              {t("desktop.settings.integrations.calendars.title")}
            </NavSubItem>
            <NavSubItem isHidden={!isAdmin} to={INTEGRATIONS_PATHS.scim}>
              {t("desktop.settings.integrations.scim.title")}
            </NavSubItem>
            <NavSubItem isHidden={!isAdmin} to={INTEGRATIONS_PATHS.apiKeys}>
              {t("desktop.settings.integrations.api.title")}
            </NavSubItem>
            <NavSubItem isHidden={!isAdmin} to={INTEGRATIONS_PATHS.chatBots}>
              {t("desktop.settings.integrations.chat_bots.title")}
            </NavSubItem>
            <NavSubItem isHidden={!isAdmin} to={INTEGRATIONS_PATHS.presence}>
              {t("desktop.settings.integrations.presence.presence_detection")}
            </NavSubItem>
          </NavMenu>
        </>
      )}

      {/*
				Organization
			*/}
      {canEditCompany && isAdmin && (
        <>
          <NavMenu
            icon={<OrganizationSVG />}
            text={t("desktop.settings.organization.title")}
            to={ORGANIZATION_PATHS.company_details}
            activePath={ORGANIZATION_PATHS.root}
          >
            <NavSubItem to={ORGANIZATION_PATHS.company_details}>
              {t("desktop.settings.organization.company_details.title")}
            </NavSubItem>
            <NavSubItem to={ORGANIZATION_PATHS.branding}>
              {t("desktop.settings.organization.branding.title")}
            </NavSubItem>
          </NavMenu>
        </>
      )}

      {/*
				Plans and billings
			*/}
      {isAdmin &&
        !isBillingDisabled &&
        (isPortalMergerEnabled ? (
          <NavMenu
            icon={<PaymentSVG />}
            text={t("desktop.settings.navigation.plans_billing.title")}
            to={BILLING_PATHS.overview.root}
            activePath={BILLING_PATHS.root}
            isDisabled={!isAdmin}
          >
            <>
              <NavSubItem
                to={BILLING_PATHS.overview.root}
                activePath={BILLING_PATHS.overview.root}
              >
                {t(
                  "desktop.settings.navigation.plans_billing.sub_items.overview",
                )}
              </NavSubItem>
              {subsByPlanGroup &&
                LIST_PLAN_GROUPS.map((group) => {
                  const value = subsByPlanGroup[group]

                  if (!value || value.length === 0) {
                    return null
                  }
                  const path = `${BILLING_PATHS.overview.root}#${group}`
                  return (
                    <NavSubItem
                      key={group}
                      to={path}
                      activePath={[path, getPlanGroupPath(group).root]}
                    >
                      {t(groupToTranslationKey(group))}
                    </NavSubItem>
                  )
                })}
            </>
          </NavMenu>
        ) : (
          <AuxButton href={BILLING_URL} isDisabled={!isAdmin}>
            <PaymentSVG />
            <span> {t("general.plans_and_billing")}</span>
          </AuxButton>
        ))}

      {isLabsEligible && (
        <AuxButton to="/settings/labs" isDisabled={!isLabsEligible}>
          <LabsSVG />
          <span> {t("general.joan_labs")}</span>
        </AuxButton>
      )}
    </AuxNav>
  )
}
